import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-bottomsheet',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './list-bottomsheet.component.html',
  styleUrl: './list-bottomsheet.component.scss'
})

export class ListBottomsheetComponent {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ListBottomsheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { type: String, listData: any[] },
    private router: Router
  ) {

  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  navigate(id: string) {
    this.close();
    this.router.navigate([this.data.type === 'Lists' ? '/list' : '/note', id]);
  }
}
