import { Injectable } from '@angular/core';
import { CF_API_LIST_NAMESPACE, CF_API_NOTE_NAMESPACE, CF_API_USER_NAMESPACE, environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class CloudFlareService {

    private apiBase = environment.cfApiBase;

    constructor(private http: HttpClient) {

    }

    getKvData(id: string, kvType: 'list' | 'note' | 'user'): Observable<any> {
        return this.http.get(`https://mevolve.pages.dev/api/${kvType}/${id}`);
        // return this.http.get(`${this.apiBase}/${kvType}/${id}`);
    }

    async getPublicList(id: string): Promise<string> {
        const key = `list-${id}`;
        const url = `${CF_API_LIST_NAMESPACE}/values/${key}`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${environment.cfApiToken}`,
            },
        });

        if (response.status !== 200) {
            throw new Error(`Failed to get value for key ${key}`);
        }

        return response.text();
    }

    async getPublicNote(id: string): Promise<string> {
        const key = `note-${id}`;
        const url = `${CF_API_NOTE_NAMESPACE}/values/${key}`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${environment.cfApiToken}`,
            },
        });

        if (response.status !== 200) {
            throw new Error(`Failed to get value for key ${key}`);
        }

        return response.text();
    }

    async getPublicUser(id: string): Promise<string> {
        const key = `user-${id}`;
        const url = `${CF_API_USER_NAMESPACE}/values/${key}`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${environment.cfApiToken}`,
            },
        });
        if (response.status !== 200) {
            throw new Error(`Failed to get value for key ${key}`);
        }
        return response.text();
    }
}
