<vg-player class="align-items-center justify-content-center w-100">
    <div class="" *ngIf="type === 'image'">
        <img class="img attach-image" src="assets/img.jpg">
    </div>
    <div class="text-center" *ngIf="type === 'document'">
        <img class="img img-fluid" src="assets/icons/file.svg">
        <p class="mb-0 ri-pt-15 ri-fs-18 ri-lh-24 ri-fw-500 ri-primary open-text" (click)="downloadEvent.emit()" role="button">OPEN FILE</p>
    </div>
    <vg-controls class="flex-column custom-controls" [vgAutohide]="true" [vgAutohideTime]="1.5">
        <div class="d-flex ps-1 my-auto">
            <img class="img w-auto img-fluid" src="assets/icons/download-white.svg" role="button" (click)="downloadEvent.emit()">
        </div>
    </vg-controls>
</vg-player>