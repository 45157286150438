import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';

@Component({
  selector: 'app-attachment-viewer',
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule
  ],
  templateUrl: './attachment-viewer.component.html',
  styleUrl: './attachment-viewer.component.scss'
})

export class AttachmentViewerComponent {

  @Input() type: 'image' | 'document' = 'image';
  @Output() downloadEvent = new EventEmitter<any>();

}
