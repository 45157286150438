import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IATTACHMENT } from '../../_interfaces/note.interface';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { CacheService } from '../../_services/cache.service';
import { AttachmentInfoBottomsheetComponent } from '../attachment-info-bottomsheet/attachment-info-bottomsheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';
import { AttachmentViewerComponent } from '../attachment-viewer/attachment-viewer.component';

@Component({
  selector: 'app-attachments-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatIconButton,
    CarouselModule,
    VideoPlayerComponent,
    AudioPlayerComponent,
    AttachmentViewerComponent
  ],
  templateUrl: './attachments-dialog.component.html',
  styleUrl: './attachments-dialog.component.scss'
})

export class AttachmentsDialogComponent implements AfterViewInit {

  customOptions: OwlOptions = {};
  isLoad: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AttachmentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AttachmentDialogModel,
    public cc: CacheService,
    private bottomSheet: MatBottomSheet
  ) {

  }

  ngAfterViewInit() {
    this.customOptions = {
      loop: false,
      margin: 0,
      nav: false,
      dots: false,
      items: 1,
      startPosition: this.data.startPosition
    }
    this.isLoad = true;
  }

  openInfoBottomSheet(e: any, attach: IATTACHMENT) {
    this.bottomSheet.open(AttachmentInfoBottomsheetComponent, {
      data: attach
    });

    e.stopPropagation();
  }

  close() {
    this.dialogRef.close();
  }

  downloadAttachment(attachment: IATTACHMENT): void {
    const fileUrl = 'https://www.antennahouse.com/hubfs/xsl-fo-sample/pdf/basic-link-1.pdf';
    const fileName = attachment.originalFileName;
    this.cc.downloadFileFromUrl(fileUrl, fileName);
  }
}

export class AttachmentDialogModel {
  constructor(
    public title: string,
    public attachment: number,
    public startPosition: number,
    public attachments: IATTACHMENT[]
  ) { }
}