import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

@Component({
  selector: 'app-video-player',
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  templateUrl: './video-player.component.html',
  styleUrl: './video-player.component.scss'
})

export class VideoPlayerComponent {

  @ViewChild('vgVideoPlayer') videoPlayer!: ElementRef;
  @ViewChild('scrubBar') scrubBar!: ElementRef;
  private rotationAngle = 0;
  @Output() downloadEvent = new EventEmitter<any>();

  seekVideo(event: MouseEvent) {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    const scrubBar = event.currentTarget as HTMLElement;

    // Calculate the percentage of the click position
    const rect = scrubBar.getBoundingClientRect();
    const percentage = (event.clientX - rect.left) / rect.width;

    // Set the current time based on the percentage
    video.currentTime = video.duration * percentage;
  }

  preventToggle(event: MouseEvent) {
    event.stopPropagation(); // Prevents click event from bubbling up
  }

  get videoRotation(): string {
    return `rotate(${this.rotationAngle}deg)`;
  }

  get containerTransform(): string {
    // Adjust container size for 90 and 270-degree rotations
    if (this.rotationAngle % 180 !== 0) {
      return 'rotate(0deg)';
    }
    return 'rotate(0deg)';
  }

  rotateVideo() {
    // Rotate 90 degrees clockwise
    this.rotationAngle += 90;

    // Reset to 0 after a full rotation
    if (this.rotationAngle >= 360) {
      this.rotationAngle = 0;
    }

    // Optional: Log current rotation for debugging
    console.log(`Current rotation: ${this.rotationAngle}°`);
  }

  onTimeUpdate(event: Event) {
    // console.log('Time update', event);
  }


}
