export const CF_ACCOUNT_ID = "78047d545833efe0615ef334aae78a9c";
export const CF_USER_NAMESPACE_ID = "4d8385fbc7c54da1891705f68ae0da15";
export const CF_NOTE_NAMESPACE_ID = "2d0600135ff14abfa1feca0c9be3a1fc";
export const CF_LIST_NAMESPACE_ID = "131468c2fced4898afa968b1c7d30197";
export const CF_API_TOKEN = "Kz3q_KD4Z4g5ImG-lapdjcFclFSNpqP2UCtnZyyC";
export const CF_API_BASE = `https://api.cloudflare.com/client/v4/accounts/${CF_ACCOUNT_ID}/storage/kv/namespaces`;
export const CF_API_USER_NAMESPACE = `${CF_API_BASE}/${CF_USER_NAMESPACE_ID}`;
export const CF_API_NOTE_NAMESPACE = `${CF_API_BASE}/${CF_NOTE_NAMESPACE_ID}`;
export const CF_API_LIST_NAMESPACE = `${CF_API_BASE}/${CF_LIST_NAMESPACE_ID}`;

export const environment = {
    env: 'dev',
    cfApiBase: "https://mevolve.aniket-singh.workers.dev",
    cfApiToken: "Kz3q_KD4Z4g5ImG-lapdjcFclFSNpqP2UCtnZyyC"
};
