<vg-player class="align-items-center justify-content-center w-100">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>
    <audio class="d-none" id="attachmentAudioPlayer" #vgAudioPlayer [vgMedia]="$any(vgAudioPlayer)" preload="auto" controls>
        <source src="https://actions.google.com/sounds/v1/alarms/digital_watch_alarm_long.ogg" type="audio/ogg">
    </audio>
    <div class="waveform-container" #waveformContainer></div>
    <vg-controls class="flex-column custom-controls" [vgAutohide]="true" [vgAutohideTime]="1.5">
        <div class="d-flex">
            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
            <div class="custom-progress-container">
                <vg-scrub-bar #scrubBar vgFor="attachmentAudioPlayer" [vgSlider]="true" (click)="seekVideo($event)">
                    <vg-scrub-bar-current-time vgFor="attachmentAudioPlayer" ></vg-scrub-bar-current-time>
                    <vg-scrub-bar-buffering-time vgFor="attachmentAudioPlayer" ></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>
            </div>

            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        </div>

        <div class="d-flex ps-1">
            <img class="img w-auto img-fluid" src="assets/icons/download-white.svg" role="button" (click)="downloadEvent.emit()">
        </div>

    </vg-controls>
</vg-player>