import { Routes } from '@angular/router';
import { ListSharingComponent } from './components/list-sharing/list-sharing.component';
import { NoteSharingComponent } from './components/note-sharing/note-sharing.component';
import { ProfileComponent } from './components/profile/profile.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

export const routes: Routes = [
    { path: 'list/:id', component: ListSharingComponent },
    { path: 'note/:id', component: NoteSharingComponent },
    { path: 'user/:id', component: ProfileComponent },
    { path: '**', component: NotFoundComponent }
];

