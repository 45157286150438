<div class="ri-bg-dark-1">
    <div class="bs-header ri-bg-secondary">
        <div class="d-flex justify-content-between ri-p-15">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">Info</h5>
            <div class="d-flex align-items-center">
                <img class="img img-fluid" type="button" src="assets/icons/close.svg" (click)="close()">
            </div>
        </div>
    </div>
    <div class="bs-body pb-0">
        <div class="ri-p-15 d-flex justify-content-between">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20 ri-pe-15">Name</p>
            <div class="text-end overflow-hidden">
                <p class="ri-fs-14 ri-primary mb-0 ri-fw-500 ri-lh-20 break-text text-start">{{ data.originalFileName }}</p>
            </div>
        </div>
        <div class="ri-p-15 d-flex justify-content-between">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20 ri-pe-15">Size</p>
            <p class="ri-fs-14 ri-white mb-0 ri-fw-500 ri-lh-20 break-text text-end">{{ convertFileSize(data.size) }}</p>
        </div>
        <div class="ri-p-15 d-flex justify-content-between">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20 ri-pe-15">Dimensions</p>
            <p class="ri-fs-14 ri-white mb-0 ri-fw-500 ri-lh-20 break-text text-end">{{ data.metadata.width }} &#xd7; {{ data.metadata.height }}</p>
        </div>
        <div class="ri-p-15 d-flex justify-content-between">
            <p class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20 ri-pe-15">Added</p>
            <p class="ri-fs-14 ri-white mb-0 ri-fw-500 ri-lh-20 break-text text-end">{{ data.createdAt | date:'d MMM y, h:mm a' }}</p>
        </div>
    </div>
</div>