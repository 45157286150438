import { Injectable } from '@angular/core';
import { CloudFlareService } from './cloudflare.service';
import { IUSER } from '../_interfaces/user.interface';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class CacheService {

    user!: IUSER;
    unSubscribe = new Subject<void>();

    constructor(private cfs: CloudFlareService, private http: HttpClient) {

    }

    getPublicUser(id: string) {
        this.cfs.getKvData(id, 'user').subscribe(response => {
            if (response.data) {
                this.user = response.data;
            } else {
                throw new Error(`Failed to get value for id ${id}`);
            }
        })
    }

    getUserDisplayLetter(): string {
        return this.user ? this.user.name.charAt(0) : '-';
    }

    stopSubscription() {
        this.unSubscribe?.next();
        this.unSubscribe?.complete();
    }

    downloadFileFromUrl(url: string, fileName: string): void {
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob | MediaSource) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(objectUrl); // Clean up memory
        });
    }
}
