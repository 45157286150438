<vg-player>
    <div class="video-wrapper" [style.transform]="containerTransform">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>
        <video class="rotatable-video" id="attachmentVideoPlayer" #vgVideoPlayer [vgMedia]="$any(vgVideoPlayer)" preload="auto" (timeupdate)="onTimeUpdate($event)" (click)="preventToggle($event)" [style.transform]="videoRotation">
            <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4">
        </video>
    
        <vg-controls class="flex-column custom-controls" [vgAutohide]="true" [vgAutohideTime]="1.5">
            <div class="d-flex">
                <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
                <div class="custom-progress-container">
                    <vg-scrub-bar #scrubBar vgFor="attachmentVideoPlayer" [vgSlider]="true" (click)="seekVideo($event)">
                        <vg-scrub-bar-current-time vgFor="attachmentVideoPlayer" ></vg-scrub-bar-current-time>
                        <vg-scrub-bar-buffering-time vgFor="attachmentVideoPlayer" ></vg-scrub-bar-buffering-time>
                    </vg-scrub-bar>
                </div>
    
                <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
            </div>
    
            <div class="d-flex ps-1">
                <img class="img w-auto img-fluid ri-pe-15" src="assets/icons/rotate.svg" role="button" (click)="rotateVideo()">
                <img class="img w-auto img-fluid" src="assets/icons/download-white.svg" role="button" (click)="downloadEvent.emit()">
            </div>
    
        </vg-controls>
    </div>
</vg-player>