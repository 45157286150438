import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IATTACHMENT } from '../../_interfaces/note.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-attachment-info-bottomsheet',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './attachment-info-bottomsheet.component.html',
  styleUrl: './attachment-info-bottomsheet.component.scss'
})

export class AttachmentInfoBottomsheetComponent {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<AttachmentInfoBottomsheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IATTACHMENT
  ) {

  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  convertFileSize(sizeInKB: number): string {
    if (sizeInKB === 0) return '0 KB';

    const sizes = ['KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(sizeInKB) / Math.log(1024)); // Determine the size unit index
    const size = sizeInKB / Math.pow(1024, i); // Convert to the corresponding unit
    return `${size.toFixed(2)} ${sizes[i]}`; // Format the size with 2 decimal points
  }
}
