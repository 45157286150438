import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule, VgMediaDirective } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import WaveSurfer from 'wavesurfer.js';

@Component({
  selector: 'app-audio-player',
  standalone: true,
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  templateUrl: './audio-player.component.html',
  styleUrl: './audio-player.component.scss'
})

export class AudioPlayerComponent {

  @ViewChild('waveformContainer', { static: true }) waveformContainer!: ElementRef;
  @ViewChild('vgAudioPlayer') audioPlayer!: ElementRef;
  @Output() downloadEvent = new EventEmitter<any>();
  @ViewChild(VgMediaDirective) media!: VgMediaDirective;
  wavesurfer!: WaveSurfer;
  currentTime: number = 0;  // Track current time
  duration: number = 0;     // Total duration
  isPlaying: boolean = false;

  ngOnInit() {
    // Initialize Wavesurfer
    this.wavesurfer = WaveSurfer.create({
      container: this.waveformContainer.nativeElement,
      waveColor: '#4B4B4D',
      progressColor: '#5A9C7D',
      height: 50,
      backend: 'WebAudio',  // Ensures WebAudio backend is used for rendering
      normalize: true,      // Normalizes the waveform (optional)
      barWidth: 2,          // Adjust the width of the bars
      barHeight: 1,         // Adjust the height of the bars
      cursorWidth: 1
    });

    // Load an audio file
    this.wavesurfer.load('https://actions.google.com/sounds/v1/alarms/digital_watch_alarm_long.ogg');

    // Listen to events
    this.wavesurfer.on('ready', () => {
      console.log('Waveform ready');
      this.duration = this.wavesurfer.getDuration();  // Set the total duration
    });

    // Update progress bar during playback
    this.wavesurfer.on('audioprocess', () => {
      this.currentTime = this.wavesurfer.getCurrentTime();  // Update current time
    });
  }

  ngAfterViewInit() {
    // Add event listeners after view is initialized
    if (this.media) {
      this.media.subscriptions.play.subscribe(() => {
        this.isPlaying = true;
        console.log("i am called playy-->>>>", this.wavesurfer)
        this.wavesurfer.play();
        console.log('Play event triggered');
      });

      this.media.subscriptions.pause.subscribe(() => {
        this.isPlaying = false;
        this.wavesurfer.pause();
        console.log('Pause event triggered');
      });
    }
  }

  seekVideo(event: MouseEvent) {
    const video: HTMLVideoElement = this.audioPlayer.nativeElement;
    const scrubBar = event.currentTarget as HTMLElement;
  
    // Calculate the percentage of the click position
    const rect = scrubBar.getBoundingClientRect();
    const percentage = (event.clientX - rect.left) / rect.width;
  
    // Calculate the new time
    const newTime = video.duration * percentage;
  
    // Set the current time for the video
    video.currentTime = newTime;
  
    // Update WaveSurfer to the same time
    if (this.wavesurfer) {
      this.wavesurfer.seekTo(percentage);
    }
  }

  playPause() {
    this.wavesurfer.playPause();
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${minutes}:${sec < 10 ? '0' : ''}${sec}`;
  }

  ngOnDestroy() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
  }

}
