<div class="ri-bg-dark-1">
    <div class="bs-header ri-bg-secondary">
        <div class="d-flex justify-content-between ri-p-15">
            <h5 class="ri-fs-18 ri-white-2 mb-0 ri-fw-500 ri-lh-24">{{ data.type }}</h5>
            <div class="d-flex align-items-center">
                <img class="img img-fluid" type="button" src="assets/icons/close.svg" (click)="close()">
            </div>
        </div>
    </div>
    <div class="bs-body pb-0" *ngIf="data.type === 'Lists'">
        <div class="list-block ri-px-15 ri-bb-1px ri-pt-15 pb-3" *ngFor="let item of data.listData;" role="button" (click)="navigate(item.id)">
            <p class="ri-fs-16 ri-fw-500 ri-lh-20 mb-0 ri-white">{{ item.title }}</p>
            <div class="ri-pt-15">
                <p class="ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14">
                    <img class="img img-fluid pe-1" src="assets/icons/circle-check.svg">
                    <span>{{ item.completedCount }}/{{ item.itemCount }}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="bs-body pb-0" *ngIf="data.type === 'Notes'">
        <div class="list-block ri-px-15 ri-bb-1px ri-pt-15 pb-3" *ngFor="let item of data.listData;" role="button" (click)="navigate(item.id)">
            <p class="ri-fs-16 ri-fw-500 ri-lh-20 mb-0 ri-white">{{ item.title }}</p>
            <div class="d-flex ri-pt-15">
                <p class="ri-pe-15 ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14"><img class="img img-fluid pe-1" src="assets/icons/calendar.svg">{{ item.updatedAt | date:'d MMM y'}}</p>
                <p class="ri-pe-15 ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14"><img class="img img-fluid pe-1" src="assets/icons/time.svg">{{ item.updatedAt | date:'h:mm a'}}</p>
                <p class="ri-pe-15 ri-secondary-2 ri-fs-12 ri-fw-400 mb-0 ri-lh-14">
                    <img class="img img-fluid pe-1" src="assets/icons/attachment.svg">
                    <span>{{ item.attachmentCount && item.attachmentCount > 10 ? '10+' : item.attachmentCount }}</span>
                </p>
            </div>
        </div>
    </div>
</div>