<main class="attachments-dialog pb-3 ri-bg-dark-1">
    <div class="d-flex ri-p-15 justify-content-between">
        <h6 class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20">{{ data.title }}</h6>
        <img class="img img-fluid" src="assets/icons/close.svg" role="button" (click)="close()">
    </div>
    <div class="ri-px-15 attachment-body">
        <owl-carousel-o class="w-100" [options]="customOptions" *ngIf="isLoad">
            <ng-template carouselSlide *ngFor="let item of data.attachments">
                <div class="item w-100">
                    <div class="d-flex ri-pb-15 justify-content-between">
                        <h6 class="ri-fs-16 ri-white mb-0 ri-fw-500 ri-lh-20 text-nowrap text-truncate">
                            <img class="img img-fluid pe-1 w-auto d-inline-flex" src="assets/icons/video.svg"> {{
                            item.originalFileName }}
                        </h6>
                        <img class="img img-fluid w-auto" src="assets/icons/info.svg" role="button" (click)="openInfoBottomSheet($event, item)">
                    </div>
                    <div class="ri-attachment" *ngIf="item.fileType === 'video'">
                        <app-video-player (downloadEvent)="downloadAttachment(item)"></app-video-player>
                    </div>
                    <div class="ri-attachment" *ngIf="item.fileType === 'audio'">
                        <app-audio-player></app-audio-player>
                    </div>
                    <div class="ri-attachment" *ngIf="item.fileType === 'image' || item.fileType === 'document'">
                        <app-attachment-viewer class="h-100" [type]="item.fileType"></app-attachment-viewer>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</main>