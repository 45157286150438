import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CloudFlareService } from '../../_services/cloudflare.service';
import { CacheService } from '../../_services/cache.service';
import { IATTACHMENT, INOTE } from '../../_interfaces/note.interface';
import { emojis } from '../../_svg/emojis.svg';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { ShareBottomsheetComponent } from '../../shared/share-bottomsheet/share-bottomsheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AttachmentDialogModel, AttachmentsDialogComponent } from '../../shared/attachments-dialog/attachments-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-note-sharing',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    QuillModule,
    RouterLink,
  ],
  templateUrl: './note-sharing.component.html',
  styleUrl: './note-sharing.component.scss'
})

export class NoteSharingComponent {

  emojis: string[] = emojis;
  noteData!: INOTE;
  unSubscribe = new Subject<void>();
  sanitizedEmojis: { [key: string]: SafeHtml } = {};
  quillModules = {
    toolbar: false
  };
  extensionsMap: Record<string, string> = {}

  constructor(
    private route: ActivatedRoute, 
    private cfs: CloudFlareService, 
    public cc: CacheService, 
    private sanitizer: DomSanitizer,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog) {
    // this.emojis.forEach(emoji => {
    //   this.loadSvg(emoji);
    // });

  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.getPublicNote(id || '');
  }

  getSanitizedSvg(svg: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }

  openShareBottomSheet(e: any) {
    this.bottomSheet.open(ShareBottomsheetComponent, {
      data: {
        title: 'Note Share',
        qrTitle: this.noteData.title,
        type: 'Note'
      }
    });

    e.stopPropagation();
  }

  viewAttachments(attachment: IATTACHMENT, index: number) {
    this.dialog.open(AttachmentsDialogComponent, {
      disableClose: true,
      maxWidth: '800px',
      width: '100%',
      data: new AttachmentDialogModel(
        this.noteData.title,
        attachment.id,
        index,
        this.noteData.attachmentsUrl
      )
    });
  }

  getAttachmentType(fileName: string): string | "UNKNOWN" {
    const extension = fileName.split('.').pop()?.toLowerCase();
    if (!extension) {
      return "UNKNOWN"; // No extension found
    }
    return this.extensionsMap[extension] || "UNKNOWN";
  }

  loadSvg(emoji: string) {
    fetch(`assets/emojis/${emoji}`)
      .then(response => response.text())
      .then(svgContent => {
        this.sanitizedEmojis[emoji] = this.sanitizer.bypassSecurityTrustHtml(svgContent);
      })
      .catch(error => console.error('Error loading SVG:', error));
  }

  getPublicNote(id: string) {
    this.cfs.getKvData(id, 'note').pipe(takeUntil(this.unSubscribe)).subscribe(response => {
      if (response.data) {
        this.noteData = response.data;
        this.cc.getPublicUser(this.noteData.userId);
      } else {
        throw new Error(`Failed to get value for id ${id}`);
      }
    })
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
    this.cc.stopSubscription();
  }

}
